<template>
  <div class="view pa24">
    <el-button
        class="mr10"
        type="primary"
        v-if="jurisdictionA  || userInfo.roleId || userInfo.main"
        @click="
        centerDialogVisible = true;
        bdetermine = true;
        editUpdate = false
      "
    >+ 添加视频
    </el-button
    >
    <el-button
        v-if="!zzInfo"
        class="mr10"
        type="primary"
        @click="centerDialogVisible_zz = true;"
    >+ 申请资质
    </el-button
    >
    <el-button
        v-if="zzInfo.status == '已驳回' || zzInfo.status == 0"
        class="mr10"
        type="primary"
        @click="updateZZ"
    >+ 修改资质
    </el-button
    >
    <el-button
        v-if="zzInfo.status == '已通过' || zzInfo.status == 2"
        class="mr10"
        type="primary"
        @click="centerDialogVisible = true;isTG = true;bdetermine = true;editUpdate = false"
    >+ 添加推广视频
    </el-button
    >
    <el-button
        v-if="zzInfo"
        class="mr10"
        type="primary"
        @click="centerDialogVisible_zzV = true;"
    >+ 查看资质
    </el-button
    >
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column align="center" type="index" label="序号"/>
        <el-table-column
            prop="userName"
            align="center"
            label="上传者"
            width="100"
            show-overflow-tooltip
        />
        <el-table-column prop="uploadTime" align="center" label="上传时间 " show-overflow-tooltip/>
        <el-table-column prop="playVolume" width="80" align="center" label="播放量pv"/>
        <el-table-column prop="guestsNum" width="80" align="center" label="获客人数">
          <template slot-scope="scope">
            <span style="color: #fd634e">{{ scope.row.guestsNum }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="likesNum" width="50" align="center" label="点赞"/>
        <el-table-column prop="forwardNum" width="80" align="center" label="转发次数"/>
        <el-table-column prop="attentionNum" width="80" align="center" label="关注人数"/>
        <el-table-column prop="title" align="center" label="标题" show-overflow-tooltip/>
        <el-table-column
            align="center"
            prop="videoGoods"
            label="推荐商品"
            width="150"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.videoGoods">
              <span v-for="(item, index) in scope.row.videoGoods" :key="index"
              >{{ item.name }}、</span
              >
            </div>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" align="center" label="地址" show-overflow-tooltip/>
        <el-table-column align="center" prop="coverImg" label="封面">
          <template slot-scope="scope">
            <el-image
                style="width: 60px; height: 60px"
                :src="scope.row.coverImg"
                fit="contain"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" width="100" prop="state" label="状态">
          <template slot-scope="scope">
            {{ scope.row.state == 0 ? "已下架" : "已上架" }}
          </template>
        </el-table-column>
        <el-table-column prop="videoType" width="80" align="center" label="视频类型">
          <template slot-scope="scope">
            {{ scope.row.videoType == 1 ? '推广视频':'-' }}
          </template>
        </el-table-column>
        <el-table-column width="80" align="center" label="审核状态" :render-header="renderHeader3">
          <template slot-scope="scope">
            <div v-if="scope.row.videoType == 1">
              <el-tooltip class="item" effect="dark" :content="scope.row.adminRefuldReason" placement="top-start">
                <span v-if="scope.row.adminState == 0" style="color: red;cursor: pointer">已驳回</span>
              </el-tooltip>
              <span v-if="scope.row.adminState == 1">待审核</span>
              <span v-if="scope.row.adminState == 2">已通过</span>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
                type="text"
                @click="
                edit(scope.row, false);
                bEdit = true;
              "
            >详情
            </el-button
            >
            <el-button
                type="text"
                @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              "
            >编辑
            </el-button
            >
            <!-- <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @onConfirm="del(scope.row.videoId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
            >
              <el-button
                slot="reference"
                class="ml10"
                style="color: #fd634e"
                type="text"
                >删除</el-button
              >
            </el-popconfirm> -->
            <customPopconfirm
                confirm-button-text="确定"
                k cancel-button-text="取消"
                @confirm="del(scope.row.videoId)"
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
              >删除
              </el-button
              >
            </customPopconfirm>
            <customPopconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="updateData(scope.row)"
                icon="el-icon-info"
                icon-color="red"
                :title=" scope.row.state == 0 ? '确定要上架这个视频吗？' : '确定要下架这个视频吗？'"
            >
              <el-button slot="reference" class="ml10" type="text" size="small">
                {{ scope.row.state == 0 ? "上架" : "下架" }}
              </el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="添加视频"
        :visible.sync="centerDialogVisible"
        @open="openDialog"
        @close="close"
        :modal-append-to-body="false"
        :destroy-on-close="true"
        width="50%"
        center
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="78px"
      >
        <el-form-item label="视频名称" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="添加位置" prop="address">
          <el-input id="suggestId" v-model="ruleForm.address"></el-input>
          <div id="baidumap"></div>
        </el-form-item>
        <template v-if="bEdit">
          <el-form-item label="上传时间">
            <el-input disabled v-model="ruleForm.uploadTime"></el-input>
          </el-form-item>
          <el-form-item label="播放量">
            <el-input disabled v-model="ruleForm.playVolume"></el-input>
          </el-form-item>
          <el-form-item label="获客人数">
            <el-input disabled v-model="ruleForm.guestsNum"></el-input>
          </el-form-item>
          <el-form-item label="点赞">
            <el-input disabled v-model="ruleForm.likesNum"></el-input>
          </el-form-item>
          <el-form-item label="转发">
            <el-input disabled v-model="ruleForm.forwardNum"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="视频内容" prop="url">
          <el-upload
              class="avatar-uploader"
              :action="$store.state.uploadingUrl"
              :show-file-list="false"
              accept=".mp4"
              :on-progress="handProgress"
              :on-success="handleAvatarSuccessVideo"
              :before-upload="beforeAvatarUploadVideo"
          >
            <video
                v-if="ruleForm.url"
                :src="ruleForm.url"
                width="100%"
                height="100%"
                controls
            >
              <source src="movie.mp4" type="video/mp4"/>
            </video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传MP4文件，且不超过30M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频封面" prop="coverImg">
          <el-upload
              :class="{
              'avatar-uploader': true,
              'avatar-coverImg': true,
              disabled: uploadDisabled,
            }"
              :action="$store.state.uploadingUrl"
              list-type="picture-card"
              :on-progress="handProgress"
              :on-success="handleAvatarSuccessCoverImg"
              :on-remove="handleRemove"
              :file-list="fileList"
              :limit="1"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              上传图片的最佳尺寸：5:3，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item  v-if="isTG">
          <div style="display: flex">
            <el-checkbox v-model="ruleForm.checked">已阅读并同意</el-checkbox>
            <div @click="centerDialogVisible_tk = true" style="color: #51CDCB;cursor: pointer">《推广协议》</div>
          </div>
        </el-form-item>
        <el-form-item label="推荐商品" prop="suggestions" v-if="!isTG">
          <el-input
              v-model="ruleForm.suggestions"
              maxlength="15"
              placeholder="请输入商品推荐语"
              style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="" v-if="!bEdit&&!isTG">
          <el-button
              type="primary"
              style="margin-left: 0px; margin-bottom: 30px"
              @click="dialogDetailVisible = true"
          >商品选择
          </el-button
          >
        </el-form-item>
        <el-form-item label="" v-if="!bEdit">
          <div
              class="d-flex justify-content-between align-items-center select-goods-list"
              v-for="(item, index) in ruleForm.videoGoods"
              :key="index"
          >
            <div>{{ item.name }}</div>
            <div>￥{{ item.price }}</div>
            <i class="el-icon-delete" @click="goodsDelete(index)"></i>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine" v-if="bdetermine"
        >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
        title="选择商品"
        :visible.sync="dialogDetailVisible"
        :modal-append-to-body="false"
        :destroy-on-close="true"
        width="50%"
        center
    >
      <commonTable
          :tableData="goodsList"
          :loading="goodsListLoading"
          @handleSizeChange="goodsListSizeChange"
          @handleCurrentChange="goodsListCurrentChange"
          @handleSelectionChange="handleSelectionChange"
          :currentPage="goodsListCurrentPage"
          :total="goodsListTotal"
      >
        <template v-slot:table>
          <el-table-column align="center" type="selection"></el-table-column>
          <el-table-column
              prop="goodsId"
              align="center"
              label="产品id"
              show-overflow-tooltip
          />
          <el-table-column prop="goodsName" align="center" label="产品 "/>
          <el-table-column prop="goodsTypeName" align="center" label="分类"/>
          <el-table-column prop="price" align="center" label="价格"/>
          <el-table-column prop="stockNum" align="center" label="库存"/>
          <el-table-column prop="dealNum" align="center" label="总销量"/>
          <el-table-column prop="createTime" align="center" label="发布时间">
            <template slot-scope="scope">
              {{ scope.row.createTime | getDataTimeSec }}
            </template>
          </el-table-column>
        </template>
      </commonTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDetailVisible = false">取 消</el-button>
        <el-button @click="queding" type="primary">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="申请资质"
        :visible.sync="centerDialogVisible_zz"
        :modal-append-to-body="false"
        :destroy-on-close="true"
        width="50%"
        center
        @close="closeZZ"
    >
      <el-form
          :model="ruleForm_zz"
          :rules="rules_zz"
          ref="ruleForm_zz"
          label-width="78px"
      >
        <el-form-item label="申请原因" prop="details">
          <quill-editor
              class="ql-editor"
              style="height: 100%"
              v-model="ruleForm_zz.details"
              ref="myQuillEditor"
              @ready="ready($event)"
              :options="editorOption"
              @change="onEditorChange($event)"
          />
          <span class="wordNumber">{{ TiLength }}/10000</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_zz = false">取 消</el-button>
        <el-button @click="shengqing" type="primary">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="资质详情"
        :visible.sync="centerDialogVisible_zzV"
        :modal-append-to-body="false"
        :destroy-on-close="true"
        width="50%"
        center
    >
      <div class="zz_wrap">
        <div class="zz_time">
          <div class="title">申请时间：</div>
          <div>{{ zzInfo.createTime }}</div>
        </div>
        <div class="zz_state">
          <div class="title">审核状态：</div>
          <div>{{ zzInfo.status }}</div>
        </div>
        <div class="zz_contnet">
          <div class="title">资质内容：</div>
          <div v-html="zzInfo.content"></div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        title="推广协议"
        :visible.sync="centerDialogVisible_tk"
        :modal-append-to-body="false"
        :destroy-on-close="true"
        width="50%"
        center
    >
      <div class="zz_wrap">
        1.用户上传的视频必须符合国家法律法规和社会公德，不得含有任何违法、违规、淫秽、暴力、恐怖、诈骗、侵权等内容。
        <br>
        <br>
        2.用户上传的视频必须是原创或者已经获得合法授权，不得侵犯任何第三方的知识产权、肖像权、隐私权等合法权益，对于侵犯第三方版权产生的版权纠纷和责任由上传用户承担。
        <br>
        <br>
        3.用户上传的视频必须制作精良，不得存在画面模糊、声音嘈杂、内容单一等问题，以确保用户体验和平台形象。
        <br>
        <br>
        4.用户上传的视频必须面向企业服务的内容，主要包括工商财税，知识产权，品牌设计IT/软件，营销推广，法律服务等，以确保视频内容与平台定位相符，为企业用户提供有价值的服务。
        <br>
        <br>
        5.用户上传的视频必须具有一定的实用性和可操作性，能够为企业用户提供实际的帮助和指导，以确保视频内容的实用性和用户体验。
        <br>
        <br>
        6.用户上传的视频必须符合商业道德和职业操守，不得存在任何虚假宣传、恶意竞争等问题，以确保视频内容的诚信性和公正性。
        <br>
        <br>
        7.用户上传的视频不得是纯广告视频，不得存在过度宣传、虚假宣传等问题，以确保视频内容的真实性和客观性。
        <br>
        <br>
        8.用户上传的视频不得传播迷信、反动思想等内容，不得存在任何政治、宗教、种族歧视等问题，以确保社会和谐和平台形象。
        <br>
        <br>
        9.用户上传的视频一旦被平台审核通过，即视为同意授权平台将该视频推送到各个商会平台，以便更好地服务于企业用户。
        <br>
        <br>
        10.用户上传的视频一旦被平台审核通过，即视为同意授权平台在必要时对该视频进行编辑、剪辑、调整等操作，以便更好地服务于企业用户。
        <br>
        <br>
        11.用户上传的视频一旦被平台审核通过，即视为同意授权平台在必要时对该视频进行删除、下架等操作，以便更好地维护平台形象和用户权益。
        <br>
        <br>
        12.用户上传的视频一旦被平台审核通过，即视为同意遵守平台的其他相关规定和协议，以便更好地维护平台形象和用户权益。
        <br>
        <br>
        13.平台有权根据实际情况对本协议进行修改和完善，用户应及时关注并遵守最新的协议条款。
        以上是用户视频上传协议的全部内容，请您仔细阅读并遵守。如果您有任何疑问或者意见，请及时联系我们的客服人员。感谢您的支持和理解！
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {lineHeightStyle} from "@/utils/lineHeight.js";
import {quillEditor, Quill} from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import {ImageDrop} from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

quillConfig.placeholder = '请输入内容'

import commonTable from "@/components/common/commonTable";
import {mapState} from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  queryPage,
  add,
  deleteById,
  updateData,
  update,
  getGoodsInfoPC,
  addComapnyQualificat,
  updateComapnyQualificat,
  queryAllComapnyQualificat,
  addPromotionVideo
} from "@/api/videoManage";
import {getDataTimeSec} from "@/utils";

let ac;
export default {
  data() {
    return {
      TiLength: 0,
      editorOption: quillConfig,
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      centerDialogVisible: false,
      dialogDetailVisible: false,
      centerDialogVisible_zz: false,
      centerDialogVisible_zzV: false,
      centerDialogVisible_tk:false,
      bEdit: false,
      bdetermine: true,
      editUpdate: true,
      isUpdate: true,
      fileList: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      ruleForm: {
        title: "",
        address: "",
        url: "",
        coverImg: "",
        suggestions: "",
        videoGoods: [],
        videoId: "",
        type: 3,
        checked:false,
      },
      ruleForm_zz: {
        details: "",
      },
      rules: {
        title: [{required: true, message: "请输入视频名称", trigger: "blur"}],
        address: [
          {required: true, message: "请输入添加位置", trigger: "blur"},
        ],
        url: [{required: true, message: "请上传视频内容"}],
        coverImg: [{required: true, message: "请上传视频封面"}],
      },
      rules_zz: {
        details: [{required: true, message: "请输入申请原因", trigger: "blur"}],
      },
      jurisdictionA: '',
      jurisdictionB: '',
      isTG: false,
      zzInfo: '',
      xiugaiZZ:1,
      qualificatId:''
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  filters: {
    getDataTimeSec(timeStamp) {
      var dateTime = new Date(timeStamp);
      var no1new = dateTime.valueOf();
      var year = dateTime.getFullYear();
      var month = dateTime.getMonth() + 1;
      var day = dateTime.getDate();
      var hour = dateTime.getHours();
      var minute = dateTime.getMinutes();
      var second = dateTime.getSeconds();
      var now = new Date();
      var now_new = now.valueOf();
      var milliseconds = 0;
      var timeSpanStr;
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }

      milliseconds = now_new - no1new;

      if (milliseconds <= 1000 * 60 * 1) {
        timeSpanStr = "刚刚";
      } else if (
          1000 * 60 * 1 < milliseconds &&
          milliseconds <= 1000 * 60 * 60
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60)) + "分钟前";
      } else if (
          1000 * 60 * 60 * 1 < milliseconds &&
          milliseconds <= 1000 * 60 * 60 * 24
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + "小时前";
      } else if (
          1000 * 60 * 60 * 24 < milliseconds &&
          milliseconds <= 1000 * 60 * 60 * 24 * 15
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + "天前";
      } else if (
          milliseconds > 1000 * 60 * 60 * 24 * 15 &&
          year == now.getFullYear()
      ) {
        timeSpanStr = year + "-" + month + "-" + day;
      } else {
        timeSpanStr = year + "-" + month + "-" + day;
      }
      return timeSpanStr;
    },
  },
  computed: {
    ...mapState(['routerArr']),
    uploadDisabled() {
      return this.ruleForm.coverImg !== "";
    },
  },
  watch: {
    routerArr(val) {
      console.log(val)
      this.jurisdictionA = val.includes('080')
      this.jurisdictionB = val.includes('081')
      if (this.jurisdictionA || this.jurisdictionB) {
        this.queryPage()
      }
    }
  },
  created() {
    this.getGoodsInfoPC();
  },
  mounted() {
    console.log(this.$store.state.uploadingUrl)
    this.queryPage();
    this.getZZ()
  },
  beforeDestroy() {
    ac.removeEventListener("onConfirm", this.setValue);
  },
  methods: {
    renderHeader3(h,{column}) {
      return h('div',[
        h('span', column.label),
        h('el-tooltip',{undefined,
              props:{undefined,
                effect:'dark',
                placement:'top',
                content:''//不需要换行就直接写在这里
              },
            },
            [//新加一个div才能换行,不需要换行可以直接在上面加content
              h('div',
                  {
                    slot:"content",
                  },
                  ['审核说明']
              ),
              h('i', {undefined,
                class: 'el-icon-question',
                style: "color:#474747;margin-left:5px;cursor:pointer;"
              })],
        )
      ]);
    },
    ready() {
      Quill.register({'formats/lineHeight': lineHeightStyle}, true);
    },
    onEditorChange(e) {
      if (this.ruleForm.details == '') {
        this.TiLength = 0
      } else {
        this.TiLength = e.quill.getLength() - 1
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    async queryPage() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        queryType: 'web',
        companyId: localStorage.getItem("companyId"),
      };
      if (this.jurisdictionA) {
        data.queryType = 'web'
        if (this.jurisdictionB) {
          data.queryType = ''
        }
      } else if (this.jurisdictioB) {
        data.queryType = ''
      }

      try {
        this.loading = true;
        const result = await queryPage(data);

        this.loading = false;
        const {total, list} = result.data.pageInfo;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
      }
    },
    async getZZ() {
      let data = {
        companyId: localStorage.getItem("companyId"),
      };
      try {
        const result = await queryAllComapnyQualificat(data);
        if (result.code == 200){
          this.zzInfo = result.data[0]
          this.zzInfo.createTime = this.renderTime(this.zzInfo.createTime)
          if (this.zzInfo.status == 1) {
            this.zzInfo.status = '审核中'
          } else if (this.zzInfo.status == 0) {
            this.zzInfo.status = '已驳回'
          } else if (this.zzInfo.status == 2) {
            this.zzInfo.status = '已通过'
          }
        }else {
          this.zzInfo = ''
        }
      } catch (error) {

      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.coverImg = "";
    },
    /**@method 加载商品列表 */
    async getGoodsInfoPC() {
      let data = {
        pageSize: this.goodsListPageSize,
        pageNum: this.goodsListCurrentPage,
      };

      try {
        this.goodsListLoading = true;
        const result = await getGoodsInfoPC(data);
        this.goodsListLoading = false;
        const {total, list} = JSON.parse(result?.data?.pageInfo);
        for (let i in list) {
          list[i].price = (list[i].price / 100).toFixed(2)
        }
        this.goodsList = list;
        this.goodsListTotal = total;
      } catch (error) {
        this.goodsListLoading = false;
      }
      // const reslut
    },
    /**@method 模态框打开时的回调 */
    openDialog() {
      let map = new BMap.Map("baidumap");
      ac = new BMap.Autocomplete({
        //建立一个自动完成的对象
        input: "suggestId",
        location: map,
      });
      ac.addEventListener("onConfirm", this.setValue);
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        address: "",
        url: "",
        coverImg: "",
        suggestions: "",
        videoGoods: [],
        videoId: "",
        type: 3,
      }),
          (this.fileList = []);
      this.bEdit = false;
      this.isTG = false
    },
    setValue(e) {
      const {
        item: {value},
      } = e;
      this.ruleForm.address = `${value.city}${value.district}${value.business}`;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    goodsListSizeChange(val) {
      this.goodsListPageSize = val;
      this.getGoodsInfoPC();
    },
    goodsListCurrentChange(val) {
      this.goodsListCurrentPage = val;
      this.getGoodsInfoPC();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    goodsDelete(index) {
      this.ruleForm.videoGoods.splice(index, 1)
    },
    queding() {
      this.ruleForm.videoGoods = [];
      this.multipleSelection.forEach((item) => {
        let data = {
          companyId: item.companyId,
          createdTime: item.createTime,
          goodsId: item.goodsId,
          videoGoodId: item.goodsId,
          videoId: this.ruleForm.videoId,
          name: item.goodsName,
          price: item.price,
        };
        this.ruleForm.videoGoods.push(data);
        this.dialogDetailVisible = false;
      });
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if (this.isUpdate) {
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;

    },
    /**@method 上传成功 */
    handleAvatarSuccessVideo(res, file) {
      console.log(res)
      if (res.code == 201) {
        this.$message.error(res.message);
      }else {
        this.$message.closeAll();
        this.$message({
          message: "上传成功",
          type: "success",
        });
        this.isUpdate = true;
        this.ruleForm.url = `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${res.data}`;
      }
    },
    beforeAvatarUploadVideo(file) {
      const isLt2M = file.size / 1024 / 1024 < 30;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 30MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccessCoverImg(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${res.data}`;
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      for (let i in data.videoGoods) {
        data.videoGoods[i].price = ((data.videoGoods[i].price) / 100).toFixed(2)
      }
      this.ruleForm = data;
      console.log(this.ruleForm);
      this.ruleForm.type = b ? 1 : 2;
      this.ruleForm.coverImg = data.coverImg;
      this.fileList = [{url: data.coverImg, uid: 1}];
      this.bdetermine = b;
      this.bEdit = b;
    },
    /**@method 添加视频 */
    determine() {
      if (this.isTG) {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            if (!this.ruleForm.checked) {
              this.$message({
                message: '请阅读并勾选推广协议',
                type: "info",
              });
              return
            }else {
              try {
                let data = {
                  ...this.ruleForm,
                  link: "",
                  companyId: localStorage.getItem("companyId"),
                  state:1
                };
                this.$message({
                  message: "正在保存",
                });
                if (this.editUpdate) {
                  await update(data);
                } else {
                  await addPromotionVideo(data);
                }
                this.centerDialogVisible = false;
                this.$message.closeAll();
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                await this.queryPage();
              } catch (error) {
                this.$message.closeAll();
                this.$message({
                  message: error.message,
                  type: "error",
                });
                this.centerDialogVisible = false;
              }
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }else {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            try {
              if (this.ruleForm.suggestions == "") {
                delete this.ruleForm.suggestions;
              }
              let data = {
                ...this.ruleForm,
                link: "",
                companyId: localStorage.getItem("companyId"),
              };
              if (data.videoGoods) {
                for (let i in data.videoGoods) {
                  data.videoGoods[i].price = (data.videoGoods[i].price * 100).toFixed(0)
                }
                if (data.videoGoods.length > 0) {
                  data.goodsFrom = 5
                }
              }
              this.$message({
                message: "正在保存",
              });
              if (this.editUpdate) {
                await update(data);
              } else {
                await add(data);
              }
              this.centerDialogVisible = false;
              this.$message.closeAll();
              this.$message({
                message: "保存成功",
                type: "success",
              });
              await this.queryPage();
            } catch (error) {
              this.$message.closeAll();
              this.$message({
                message: error.message,
                type: "error",
              });
              this.centerDialogVisible = false;
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await deleteById({videoId: val});
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 上架视频 */
    async updateData(val) {
      let data = {
        videoId: val.videoId,
        state: val.state ? 0 : 1,
      };
      await updateData(data);
      await this.queryPage();
    },
    shengqing() {
      this.$refs.ruleForm_zz.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              qualificatType: 'VIDEO',
              content: this.ruleForm_zz.details,
              companyId: localStorage.getItem("companyId"),
            };
            this.$message({
              message: "正在保存",
            });
            if (this.xiugaiZZ == 1) {
              await addComapnyQualificat(data);
            }else {
              data.status = 1
              data.qualificatId = this.qualificatId
              await updateComapnyQualificat(data);
            }

            this.centerDialogVisible_zz = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getZZ()
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible_zz = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async updateZZ(){
      let data = {
        companyId: localStorage.getItem("companyId"),
      };
      try {
        const result = await queryAllComapnyQualificat(data);
        if (result.code == 200){
          this.xiugaiZZ = 2
          this.centerDialogVisible_zz = true;
          this.zzInfo = result.data[0]
          this.qualificatId = this.zzInfo.qualificatId
          this.ruleForm_zz.details = this.zzInfo.content
        }
      } catch (error) {

      }
    },
    closeZZ(){
      this.ruleForm_zz.details = ''
      this.xiugaiZZ = 1
    }
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}

/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}

/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}

.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}

.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}

/deep/ .disabled .el-upload--picture-card {
  display: none;
}

.zz_wrap {
  .title {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
  }

  .zz_time {
    display: flex;

    div:nth-child(2) {
      align-self: center;
      font-size: 16px;
    }
  }
  .zz_state {
    display: flex;
    margin: 15px 0;
    div:nth-child(2) {
      align-self: center;
      font-size: 16px;
    }
  }
  .zz_contnet {
    display: flex;
    margin: 15px 0;
    div:nth-child(2) {
      align-self: center;
      font-size: 16px;
      flex: 1;
    }
  }
}
</style>
